import * as helper from "./helper";
import IHostData from "./Interfaces/IHostData";
import {formatOverlayCallBox} from "./Callbox";
import ICountryInfo from "./Interfaces/ICountryInfo";
import {Device} from "./Types/Device";
import * as Events from "./Events";
import {ICountryMessage} from "./Interfaces/ICountryMessage";
import CTALine from "./CTALine";
import CodeHandler from "./CodeHandler";
import {dom} from "@fortawesome/fontawesome-svg-core";
import IVXCashObject from "./Interfaces/IVXCashObject";

declare var vxcash_obj: IVXCashObject;

export default class OverlayHandler {

    get node(): Element {
        return this._node;
    }
    private hostData: IHostData = {
        age: 0,
        breasts: "",
        ddi: "",
        gender: "",
        height: 0,
        images: [{
            id: 0,
            hostId: 0,
            fsk: 12,
            type: "",
            ratio: 1,
            urls: {40: "", 60: "", 140: "", 160: "", 320: "", 640: "", 1024: ""},
        }, {
            id: 0,
            hostId: 0,
            fsk: 12,
            type: "",
            ratio: 1,
            urls: {40: "", 60: "", 140: "", 160: "", 320: "", 640: "", 1024: ""},
        }, {
            id: 0,
            hostId: 0,
            fsk: 12,
            type: "",
            ratio: 1,
            urls: {40: "", 60: "", 140: "", 160: "", 320: "", 640: "", 1024: ""},
        }, {
            id: 0,
            hostId: 0,
            fsk: 12,
            type: "",
            ratio: 1,
            urls: {40: "", 60: "", 140: "", 160: "", 320: "", 640: "", 1024: ""},
        }],
        job: "",
        name: "",
        preferences: [],
        relationship: "",
        sexuality: "",
        state: "offline",
        weight: "",
        zip: "",
        id: -1,
    };

    private countryInfo: ICountryInfo = {
        country: "de",
        fontclass: "",
        info: "",
        infoShort: "",
        mobileFlag: false,
        mobileInfoText: "",
        mobileNumber: "",
        mobileNumberExtension: "",
        number: "",
        numberExtension: "",
        pin: false,
        price: "",
    };
    private device: Device = vxcash_obj.countrySelection.dePhone !== "1" && vxcash_obj.countrySelection.deMobile === "1" ? "mobile" : "phone";
    private readonly _node: Element;
    private _show: "infos" | "preferences";
    private _chatUrl: string;

    // private countrySelector: CountrySelector;

    constructor() {
        this._chatUrl = "";
        this._show = "infos";
        this._node = helper.stringToHtml(`<div id='vxcash-bas-overlay' class="${this.countryInfo.country} ${this.hostData.state}"></div>`);
        // this.countrySelector = new CountrySelector();

        // @ts-ignore
        dom.watch({
            autoReplaceSvgRoot: this._node,
            observeMutationsRoot: this._node,
        });

        helper.addEventListener(window, Events.CHANGE_COUNTRY, this.updateNumber.bind(this));
        helper.addEventListener(window, Events.BOOMER_EVENT_STATE_CHANGE, this.updateState.bind(this));
        helper.addEventListener(window, Events.NEW_CHAT_URL, this.handleChatUrl.bind(this));
        helper.addEventListener(window, Events.CLEAR_CHAT_URL, this.handleClearChatUrl.bind(this));
        helper.addEventListener(window, Events.CHANGE_DEVICE, this.handleDeviceChange.bind(this));
        helper.addEventListener(window, Events.CHANGE_COUNTRY, this.handleCountryChange.bind(this));
    }

    public updateNumber(evt: CustomEvent) {
        const data: ICountryMessage = evt.detail;
        this.device = data.device;
        this.countryInfo = data.infos;
        this._changeHtml();
    }

    public updateState(evt: CustomEvent) {
        const data = evt.detail.data;
        if (typeof data.sender !== "undefined" && typeof data.status !== "undefined") {
            if (parseInt(data.sender, 10) === this.hostData.id) {
                switch (data.status) {
                    case "0900offline":
                    case "offline":
                        this.hostData.state = "offline";
                        break;
                    case "free":
                        this.hostData.state = "online";
                        break;
                    case "videochat":
                    case "incoming":
                    case "connected":
                        this.hostData.state = data.status;
                        break;
                }

                this._changeHtml();
            }
        }
    }

    public _changeHtml() {
        this.setModalState();

        const line = formatOverlayCallBox(this.countryInfo, this.hostData.ddi, this.hostData.state, this.device, this.hostData.id);
        const box = this._getBox();
        const infos = this._getInfos();

        const rightSide = this._node.querySelector(".vxcash-bas-body .vxcash-bas-right");
        if (rightSide) {
            rightSide.innerHTML = '<p class="vxcash-bas-cta">' + CTALine(this.hostData.state) + "</p>" + line + box + infos;
            // rightSide.prepend(this.countrySelector.node);
        }
    }

    public _getInfos() {
        if (this._show === "infos") {
            return `<div class="vxcash-bas-infos">
					<div class="vxcash-bas-leftarrow"><div>&laquo;</div></div>
					<div class="vxcash-bas-infocontent">
						<div>${this.hostData.age} Jahre</div>
						<div>${this.hostData.gender}</div>
						<div>PLZ: ${this.hostData.zip}</div>
						<div>${this.hostData.height} cm</div>
						<div>${this.hostData.weight}</div>
						<div>${this.hostData.breasts}</div>
						<div>${this.hostData.sexuality}</div>
						<div>${this.hostData.job}</div>
						<div>${this.hostData.relationship}</div>
					</div>
					<div class="vxcash-bas-rightarrow"><div>&raquo;</div></div>
				</div>`;
        } else {
            let pref = "";
            for (const preference of this.hostData.preferences.slice(0, 12)) {
                pref += `<div>${preference}</div>`;
            }

            return `<div class="vxcash-bas-infos">
					<div class="vxcash-bas-leftarrow"><div>&laquo;</div></div>
					<div class="vxcash-bas-infocontent">
						${pref}
					</div>
					<div class="vxcash-bas-rightarrow"><div>&raquo;</div></div>
				</div>`;
        }
    }

    public _getBox() {
        let hide1 = "";
        let hide2 = "vxcash-bas-hidden";

        if (this._chatUrl !== "") {
            hide1 = "vxcash-bas-hidden";
            hide2 = "";
        }

        switch (this.hostData.state) {
            case "online":
            case "incoming":
                return `<p class="vxcash-bas-instruction">Frage mich nach meinem Livecam-Code.</p>
						<p class="vxcash-bas-codeentry">Trage den Code hier ein:</p>
						<div class="vxcash-bas-flexnumber">
							<input type="text" name="" placeholder="12345" class="vxcash-bas-number-input ${hide1}">
							<input type="button" value="Start" class="vxcash-bas-start-button  ${hide1}">
						</div>
						<input type="button" value="Chat öffnen" class="vxcash-bas-chat-button ${hide2}">
						`;

            case "connected":
                return `<p class="vxcash-bas-instruction">Frage mich nach meinem Livecam-Code.</p>
						<p class="vxcash-bas-codeentry">Trage den Code hier ein:</p>
							<div class="vxcash-bas-flexnumber">
								<input type="text" name="" placeholder="12345" class="vxcash-bas-number-input ${hide1}">
								<input type="button" value="Start" class="vxcash-bas-start-button ${hide1}">
							</div>
						<input type="button" value="Chat öffnen" class="vxcash-bas-chat-button ${hide2}">
						`;
            case "offline":
                return "<p><small>Ich bin derzeit leider nicht per Cam zu erreichen. Versuche es zu einem anderen Zeitpunkt erneut.</small></p>";
            case "videochat":
                return "";
        }
    }

    public show(hostData: IHostData, countryInfo: ICountryInfo) {
        this.hostData = hostData;
        this.countryInfo = countryInfo;
        this._show = "infos";
        this._node.innerHTML = this.createInnerHtml();
        // this._node.querySelector('.vxcash-bas-right').prepend(this.countrySelector.node);
        this.addEventHandler();
        document.body.appendChild(this._node);
        this.setModalState();
    }

    public hide() {
        document.body.removeChild(this._node);
    }

    private handleCountryChange(evt: CustomEvent) {
        const data: ICountryMessage = evt.detail;
        this.device = data.device;
        this.countryInfo = data.infos;
        this._changeHtml();
    }

    private handleDeviceChange(evt: CustomEvent) {
        this.device = evt.detail;
        this._changeHtml();
    }

    private handleChatUrl(evt: CustomEvent) {
        this._chatUrl = evt.detail;
        this._changeHtml();
    }

    private handleClearChatUrl() {
        this._chatUrl = "";
        this._changeHtml();
    }

    private addEventHandler() {
        const closer = this._node.querySelector<HTMLDivElement>(".vxcash-bas-close");
        const bigImage = this._node.querySelector<HTMLImageElement>("img.vxcash-bas-big");
        const images = this._node.querySelectorAll<HTMLImageElement>("img.vxcash-bas-small");
        for (const image of images) {
            helper.addEventListener(image, "click", () => {
                bigImage.src = image.src;
            });
        }

        helper.addEventListener(closer, "click", () => {
            this.hide();
        });

        helper.addEventListener(this._node.querySelector(".vxcash-bas-body .vxcash-bas-right"), "click", this.handleContentClick.bind(this));
    }

    private handleContentClick(evt: MouseEvent) {
        const element = evt.target as Element;
        const test = element.closest(".vxcash-bas-leftarrow, .vxcash-bas-rightarrow");
        if (test !== null) {
            evt.preventDefault();
            this._show = (this._show === "infos") ? "preferences" : "infos";
            this._changeHtml();
            return;
        }

        const test3 = element.closest(".vxcash-bas-chat-button");
        if (test3 !== null && this._chatUrl !== "") {
            evt.preventDefault();
            window.open(this._chatUrl);
            helper.triggerEvent(window, Events.CLEAR_CHAT_URL, "");
        }

        const test2 = element.closest(".vxcash-bas-start-button");
        if (test2 !== null) {
            evt.preventDefault();
            const code = this._node.querySelector<HTMLInputElement>(".vxcash-bas-body .vxcash-bas-right .vxcash-bas-number-input").value.toString().trim();
            CodeHandler(code);
        }
    }

    private createInnerHtml() {
        const line = formatOverlayCallBox(this.countryInfo, this.hostData.ddi, this.hostData.state, this.device, this.hostData.id);
        const box = this._getBox();
        const infos = this._getInfos();
        const cta = CTALine(this.hostData.state);

        return `<div id="vxcash-bas-modal">
					<div class="vxcash-bas-close">
						<i class="fas fa-times-circle"></i>
					</div>
					<div class="vxcash-bas-header">
						<div class="vxcash-bas-indicator"></div>
						${this.hostData.name} (${this.hostData.age})
					</div>
					<div class="vxcash-bas-body">
						<div class="vxcash-bas-left">
							<img src="${this.hostData.images[0].urls["640"]}" alt="" class="vxcash-bas-big" />
							<div class="vxcash-bas-smallimages-holder">
								<img src="${this.hostData.images[0].urls["640"]}" alt="" class="vxcash-bas-small" />
								<img src="${this.hostData.images[1].urls["640"]}" alt="" class="vxcash-bas-small" />
								<img src="${this.hostData.images[2].urls["640"]}" alt="" class="vxcash-bas-small" />
							</div>
						</div>
						<div class="vxcash-bas-right">
							<p class="vxcash-bas-cta">${cta}</p>
							${line}
							${box}
							${infos}
						</div>
					</div>
				</div>`;
    }

    private setModalState() {
        helper.removeClass(this._node, ["online", "offline", "connected", "videochat", "incoming"]);
        helper.addClass(this._node, this.hostData.state);
    }
}
