import * as helper from "./helper";
import * as Events from "./Events";
import {ICountryMessage} from "./Interfaces/ICountryMessage";
import ICountryInfo, {ICountryHolder} from "./Interfaces/ICountryInfo";
import CodeHandler from "./CodeHandler";
import IVXCashObject from "./Interfaces/IVXCashObject";
import firstCountry from "./Functions/firstCountry";
import {Country} from "./Types/Country";
import {Device} from "./Types/Device";

declare var vxcash_obj: IVXCashObject;

export default class Header {
	private countrySelector: Element;

	private static getFormatByCountry(infos: ICountryMessage) {
		const country = infos.infos;

		switch (infos.country) {
			case "de":
				if (infos.device === "phone") {
					return `
							${country.number} <span class="ddi">+ Durchwahl*</span>
							`;
				} else {
					return `
							<small>Wähle jetzt ${country.mobileNumber} und sage die PIN deines Girls</small>
							`;
				}
			case "at":
				return `
				${country.number} <span class="bas-ddi">+ Durchwahl*</span>
				`;
			case "ch":
				return `
				${country.number}<br><small>und folge den Anweisungden des System, tippe dann die <span class="bas-ddi">Pin</span> ein</small>
				`;
		}
	}

	private node: Element;
	private readonly landlineButton: Element;
	private readonly mobileButton: Element;
	private readonly countries: ICountryHolder;
	private readonly input: HTMLInputElement;
	private readonly submit: HTMLButtonElement;
	private readonly infoline: HTMLDivElement;
	private selectedCountry: Country;
	private selectedDevice: Device;
	private chatUrl: string;
	private readonly openChat: Element;

	constructor(node: Element) {
		this.node = node;
		this.infoline = node.nextElementSibling as HTMLDivElement;
		this.input = node.querySelector(".bas-numberinput");
		this.submit = node.querySelector(".bas-flexingcode .bas-submit-button");
		this.openChat = node.querySelector(".bas-chat-button");
		const buttons = node.querySelectorAll(".bas-button");
		this.landlineButton = buttons[0];
		this.mobileButton = buttons[1];
		this.countries = vxcash_obj.numbers;
		this.selectedCountry = firstCountry();
		this.selectedDevice = "phone";
		this.chatUrl = "";
		this.sizeCheck();
		this.handlePhoneButtons();
		this.node.querySelector(".bas-callnumber").innerHTML = Header.getFormatByCountry({
			device: this.selectedDevice,
			infos: this.countries[this.selectedCountry],
			country: this.selectedCountry,
		});
		this.handleInfoLine();
		this.setupCountrySelector();

		helper.addEventListener(this.landlineButton, "click", this.handleLandline.bind(this));
		helper.addEventListener(this.mobileButton, "click", this.handleMobile.bind(this));
		helper.addEventListener(this.openChat, "click", this.handleOpenChat.bind(this));
		helper.addEventListener(this.submit, "click", this.handleCode.bind(this));
		helper.addEventListener(window, "resize", this.sizeCheck.bind(this));

		helper.addEventListener(window, Events.CHANGE_COUNTRY, this.handleCountryChange.bind(this));
		helper.addEventListener(window, Events.NEW_CHAT_URL, this.handleChatUrl.bind(this));
		helper.addEventListener(window, Events.CLEAR_CHAT_URL, this.handleClearChatUrl.bind(this));
	}

	private handleChatUrl(evt: CustomEvent) {
		this.chatUrl = evt.detail;
		helper.addClass(this.input, "bas-hidden");
		helper.addClass(this.submit, "bas-hidden");
		helper.removeClass(this.openChat, "bas-hidden");
	}

	private handleClearChatUrl() {
		this.chatUrl = "";
		helper.removeClass(this.input, "bas-hidden");
		helper.removeClass(this.submit, "bas-hidden");
		helper.addClass(this.openChat, "bas-hidden");
	}

	private handleOpenChat() {
		if (this.chatUrl !== "") {
			window.open(this.chatUrl);
		}
		helper.triggerEvent(window, Events.CLEAR_CHAT_URL, "");
	}

	private handleInfoLine() {
		const countryInfo: ICountryInfo = vxcash_obj.numbers[this.selectedCountry];

		helper.removeClass(this.infoline, "de");
		helper.removeClass(this.infoline, "ch");
		helper.removeClass(this.infoline, "at");
		helper.addClass(this.infoline, countryInfo.country);

		if (this.selectedDevice === "phone") {
			this.infoline.innerHTML = countryInfo.info;
		} else {
			this.infoline.innerHTML = countryInfo.mobileInfoText;
		}
	}

	private handlePhoneButtons() {
		if (vxcash_obj.countrySelection.dePhone === "0" || vxcash_obj.countrySelection.deMobile === "0") {
			helper.hide(this.landlineButton);
			helper.hide(this.mobileButton);
		} else {
			helper.show(this.landlineButton, "inline-block");
			helper.show(this.mobileButton, "inline-block");
		}
	}

	private sizeCheck() {
		if (this.node.clientWidth < 600) {
			helper.hide(this.node.querySelector(".bas-infosection:last-child"));
			helper.hideAll(this.node.querySelectorAll(".bas-stepnumber"));
		} else {
			helper.show(this.node.querySelector(".bas-infosection:last-child"), "flex");
			helper.showAll(this.node.querySelectorAll(".bas-stepnumber"));
		}
	}

	private handleCode(evt: MouseEvent) {
		evt.preventDefault();
		const code = this.input.value.toString().trim();
		CodeHandler(code);
	}

	private handleCountryChange(evt: CustomEvent) {
		const info: ICountryMessage = evt.detail;
		this.selectedDevice = info.device;
		this.selectedCountry = info.country;

		const deMobile = vxcash_obj.countrySelection.deMobile;
		const dePhone = vxcash_obj.countrySelection.dePhone;

		if (info.country === "de" && deMobile === "1" && dePhone === "1") {
			helper.show(this.landlineButton, "inline-block");
			helper.show(this.mobileButton, "inline-block");
			if (info.device === "mobile") {
				helper.removeClass(this.landlineButton, "active");
				helper.addClass(this.mobileButton, "active");
			} else {
				helper.addClass(this.landlineButton, "active");
				helper.removeClass(this.mobileButton, "active");
			}
		} else {
			helper.hide(this.landlineButton);
			helper.hide(this.mobileButton);
		}

		this.node.querySelector(".bas-callnumber").innerHTML = Header.getFormatByCountry(info);
		this.handleInfoLine();
		this.setActive();
	}

	private handleMobile(evt: MouseEvent) {
		evt.preventDefault();
		const data: ICountryMessage = {country: "de", device: "mobile", infos: this.countries.de};
		helper.triggerEvent(window, Events.CHANGE_COUNTRY, data);
	}

	private handleLandline(evt: MouseEvent) {
		evt.preventDefault();
		const data: ICountryMessage = {country: "de", device: "phone", infos: this.countries.de};
		helper.triggerEvent(window, Events.CHANGE_COUNTRY, data);
	}

	private setupCountrySelector() {
		this.countrySelector = this.node.previousElementSibling;
		const buttons = this.countrySelector.querySelectorAll('a');

		for (const button of buttons) {
			helper.addEventListener(button, 'click', (e:MouseEvent) => {
				e.preventDefault();
				const country = helper.getData(button, 'lang');
				const data: ICountryMessage = {country: country, device: "phone", infos: this.countries[country]};
				helper.triggerEvent(window, Events.CHANGE_COUNTRY, data);
				for (const button1 of buttons) {
					helper.removeClass(button1, 'active');
				}
				helper.addClass(button, 'active');
			})
		}

		this._hideFlags();
	}

	private _hideFlags() {
		let counter = 0;
		let countrySelectorDiv = this.countrySelector.querySelector('.bas-countryselector');
		let deviceSelectorDivs = this.node.querySelector('#bas-numberSelection .bas-button');

		for (let c in vxcash_obj.countrySelection) {
			counter += parseInt(vxcash_obj.countrySelection[c], 10);
		}

		// Only one language - hide them all
		if (counter === 1) {
			helper.hide(countrySelectorDiv);
			helper.hideAll(deviceSelectorDivs);
			return;
		}

		// Two, but both are german? Still hide
		if (counter === 2 && vxcash_obj.countrySelection.dePhone === '1' && vxcash_obj.countrySelection.deMobile === '1') {
			helper.hide(countrySelectorDiv);
			return;
		}

		// hide device selector, if not both are active
		if (vxcash_obj.countrySelection.dePhone !== '1' || vxcash_obj.countrySelection.deMobile !== '1') {
			helper.hideAll(deviceSelectorDivs);
			if (vxcash_obj.countrySelection.deMobile === '1') {
				helper.triggerEvent(window, Events.CHANGE_DEVICE, 'mobile');
			}
		}

		if (this.selectedCountry !== 'de') {
			helper.hideAll(deviceSelectorDivs);
		}

		if (vxcash_obj.countrySelection.auPhone !== '1') {
			helper.hide(countrySelectorDiv.querySelector('.at'));
		}
		if (vxcash_obj.countrySelection.chPhone !== '1') {
			helper.hide(countrySelectorDiv.querySelector('.ch'));
		}
		if (vxcash_obj.countrySelection.deMobile !== '1' && vxcash_obj.countrySelection.dePhone !== '1') {
			helper.hide(countrySelectorDiv.querySelector('.de'));
		}

		// helper.show(this.countrySelectorNode, 'inline-block');
	}

	private setActive() {
		const flags = this.countrySelector.querySelectorAll('a');
		const devices = this.node.querySelectorAll('#bas-numberSelection .bas-button');

		for (const flag of flags) {
			helper.removeClass(flag, 'active')
			const lang = helper.getData(flag, 'lang');
			if (lang === this.selectedCountry) {
				helper.addClass(flag, 'active');
			}
		}
		for (const device of devices) {
			helper.removeClass(device, 'active');
			if (helper.hasClass(device, this.selectedDevice)) {
				helper.addClass(device, 'active');
			}
		}
	}
}
