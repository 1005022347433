import IVXCashObject from "./Interfaces/IVXCashObject";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import "mdn-polyfills/Element.prototype.closest";
import "mdn-polyfills/Node.prototype.replaceWith";
import {dom, library} from "@fortawesome/fontawesome-svg-core";
import {faComments, faMobile, faMobileAlt, faPhoneAlt, faTimesCircle, faVideo} from "@fortawesome/free-solid-svg-icons";
import Holder from "./Holder";
import Communicator from "./Communicator";
import Host from "./Host";
import firstCountry from "./Functions/firstCountry";
import OverlayHandler from "./OverlayHandler";
import * as helper from "./helper";
import * as Events from "./Events";
import {Country} from "./Types/Country";
import Header from "./Header";

declare var vxcash_obj: IVXCashObject;

window.addEventListener("DOMContentLoaded", () => {
    library.add(faPhoneAlt, faMobileAlt, faComments, faTimesCircle, faVideo, faMobile);

    let handler: OverlayHandler;
    let currentCountry: Country;
    const holders: Holder[] = [];
    const hostArray: Host[] = [];

    const headers = document.querySelectorAll(".bas-header");
    for (const header of headers) {
        new Header(header);
    }

    const holderElements = document.querySelectorAll(".list-holder-bas");
    if (holderElements.length > 0) {
        fetch(vxcash_obj.ajax_url + "?action=get_bas_hosts&_ajax_nonce=" + vxcash_obj.nonce, {method: "GET"})
            .then((response) => {
                return response.json();
            })
            .then((hosts) => {
                new Communicator();
                handler = new OverlayHandler();

                currentCountry = firstCountry();

                for (const host of hosts) {
                    hostArray.push(new Host(vxcash_obj.numbers[currentCountry], host, handler));
                }

                for (const index in holderElements) {
                    if (holderElements.hasOwnProperty(index)) {
                        const holder = holderElements[index];
                        holders.push(new Holder(holder, hostArray));
                        // @ts-ignore
                        dom.watch({
                            autoReplaceSvgRoot: holder,
                            observeMutationsRoot: holder,
                        });
                    }
                }

            })
            .catch((error) => {
                console.log("Something happended in the Index.ts");
                console.log(error);
            });

        helper.addEventListener(window, Events.BOOMER_EVENT_STATE_CHANGE, (evt: CustomEvent) => {
            const data = evt.detail.data;
            if (typeof data.sender !== "undefined" && typeof data.status !== "undefined") {
                const hostIds = hostArray.map(function(host) {
                    return host.id;
                });

                const isKnown = hostIds.includes(parseInt(data.sender, 10));

                if (!isKnown) {
                    fetch(vxcash_obj.ajax_url + "?action=get_single_bas_host&id=" + data.sender +
                            "&_ajax_nonce=" + vxcash_obj.nonce, {method: "GET"})
                        .then((response) => response.json())
                        .then((host) => {
                            if (host.hasOwnProperty("id")) {
                                const newHost = new Host(vxcash_obj.numbers[currentCountry], host, handler);
                                hostArray.push(newHost);
                                for (const holder of holders) {
                                    holder.update();
                                }
                            }
                        });
                }
            }
        });
    }
});
