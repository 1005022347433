import * as helper from "./helper";
import * as Events from "./Events";
import { ICountryMessage } from "./Interfaces/ICountryMessage";
import { formatHostCallBox } from "./Callbox";
import { HostState } from "./Types/HostState";
import ICountryInfo from "./Interfaces/ICountryInfo";
import IHostData, { IHostImage } from "./Interfaces/IHostData";
import { Device } from "./Types/Device";
import OverlayHandler from "./OverlayHandler";

export default class Host {
  private readonly _node: Element;
  private _countryInfo: ICountryInfo;
  private _device: Device;
  private readonly hostData: IHostData;
  private _overlayHandler: OverlayHandler;

  constructor(
    countryInfo: ICountryInfo,
    hostData: IHostData,
    overlayHandler: OverlayHandler
  ) {
    this.hostData = hostData;
    this._countryInfo = countryInfo;
    this._overlayHandler = overlayHandler;
    this._device = "phone";

    this._node = this.createHtml();

    helper.addEventListener(this._node, "click", this.showModal.bind(this));
    helper.addEventListener(
      window,
      Events.CHANGE_COUNTRY,
      this.updateCountry.bind(this)
    );
    helper.addEventListener(
      window,
      Events.CHANGE_DEVICE,
      this.updateDevice.bind(this)
    );
    helper.addEventListener(
      window,
      Events.BOOMER_EVENT_STATE_CHANGE,
      this.updateState.bind(this)
    );
    this.updateView();
  }

  updateDevice(evt: CustomEvent) {
    this._device = evt.detail;
    this.updateView();
  }

  updateCountry(evt: CustomEvent) {
    let data: ICountryMessage = evt.detail;
    this._device = data.device;
    this._countryInfo = data.infos;
    this.updateView();
  }

  updateView() {
    helper.removeClass(this._node, [
      "online",
      "offline",
      "connected",
      "videochat",
      "incoming",
    ]);
    helper.addClass(this._node, this.hostData.state);
    this._node.querySelector(".bas-callbox").innerHTML = formatHostCallBox(
      this._countryInfo,
      this.hostData.ddi,
      this.hostData.state,
      this._device
    );
  }

  updateState(evt: CustomEvent) {
    let data = evt.detail.data;
    if (
      typeof data.sender !== "undefined" &&
      typeof data.status !== "undefined"
    ) {
      if (parseInt(data.sender, 10) === this.id) {
        switch (data.status) {
          case "0900offline":
          case "offline":
            this.hostData.state = "offline";
            break;
          case "free":
            this.hostData.state = "online";
            break;
          case "videochat":
          case "incoming":
          case "connected":
            this.hostData.state = data.status;
            break;
        }

        if (data.ddi !== "" && typeof data.ddi !== "undefined") {
          this.hostData.ddi = data.ddi;
        }

        helper.triggerEvent(window, Events.SORT_LIST, {});
        this.updateView();
      }
    }
  }

  get age() {
    return this.hostData.age;
  }

  set state(value: HostState) {
    this.hostData.state = value;
  }

  get state(): HostState {
    return this.hostData.state;
  }

  get id(): number {
    return this.hostData.id;
  }

  get name(): string {
    return this.hostData.name;
  }

  get ddi(): string {
    return this.hostData.ddi;
  }

  get images(): IHostImage[] {
    return this.hostData.images;
  }

  get node(): Element {
    return this._node;
  }

  private showModal() {
    debugger;
    this._overlayHandler.show(this.hostData, this._countryInfo);
  }

  private createHtml() {
    return helper.stringToHtml(`<div class="basBlade ${this.hostData.state}" id="blade${this.hostData.id}">
											<div class="imageHolder">
												<div class="basImage" style="background-image: url(${this.hostData.images[0].urls["640"]})"></div>
												<div class="indicator"></div>
												<p class="name">${this.hostData.name}</p>
											</div>
											<div class="bas-callbox"></div>
										</div>`);
  }

  hasAnyAttribute(attributes: string[]) {
    return this.hostData.preferences.some(
      (element) => attributes.indexOf(element) > -1
    );
  }
}
