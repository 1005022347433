import * as helper from './helper';
import * as Events from './Events';
import ICodeResult from "./Interfaces/ICodeResult";
import IVXCashObject from "./Interfaces/IVXCashObject";

declare var vxcash_obj: IVXCashObject;

export default function CodeHandler(code: string) {
	if (code.length !== 5) {
		alert("Der code ist leider ungültig");
		return;
	}

	let location = window.location.href;
	if (location.indexOf('?') === -1) {
		location += '?a=1'
	}

	let formData = new FormData();
	formData.append('action', 'get_connection');
	formData.append('code', code);
	formData.append('url', location);

	fetch(vxcash_obj.ajax_url, {
		method: 'POST',
		body: formData
	})
		.then(response => response.json())
		.then((data: ICodeResult) => {
			if (data.status !== 200) {
				alert(data.text)
			} else {
				helper.triggerEvent(window, Events.NEW_CHAT_URL, data.url);
			}
		})
		.catch(error => {
			console.log("Something happened with the Codehandler");
			console.log(error);
		})
}
