import ICountryInfo from "./Interfaces/ICountryInfo";
import {HostState} from "./Types/HostState";
import CTALine, {Subline} from "./CTALine";
import {Device} from "./Types/Device";

function germanBox(phoneNumber: ICountryInfo, ddi: string, device: Device) {
	if (device === 'phone') {
		return `
		<p class="vxcash-bas-number"><a href="tel:${phoneNumber.number}${ddi}" target="_top">${phoneNumber.number} <span class="ddi">${ddi}</span></a></p>
		<p class="vxcash-bas-price">${phoneNumber.info}</p>
		`;
	} else {
		return `
		<p class="vxcash-bas-number"><a href="tel:${phoneNumber.mobileNumber}" target="_top">${phoneNumber.mobileNumber}</a><br><span class="ddi">${phoneNumber.mobileNumberExtension} ${ddi}</span></p>
		<p class="vxcash-bas-price">${phoneNumber.mobileInfoText}</p>
		`;
	}
}

function austrianBox(phoneNumber: ICountryInfo, ddi: string) {
	return `
		<p class="vxcash-bas-number"><a href="tel:${phoneNumber.number}${ddi}" target="_top">${phoneNumber.number} <span class="ddi">${ddi}</span></a></p>
		<p class="vxcash-bas-price">${phoneNumber.info}</p>
	`;
}

function swizzBox(phoneNumber: ICountryInfo, ddi: string) {
	return `
		<p class="vxcash-bas-number"><a href="tel:${phoneNumber.number}" target="_top">${phoneNumber.number}</a><br><span class="ddi">${phoneNumber.numberExtension} ${ddi}</span></p>
		<p class="vxcash-bas-price ch">${phoneNumber.info}</p>
	`;
}

export function formatHostCallBox(phoneNumber: ICountryInfo, ddi: string, state: HostState, device: Device) {
	return '<p class="vxcash-bas-cta">' + CTA(state, ddi, phoneNumber, device) + '</p>';
}

function CTA(state: HostState, ddi: string, phoneNumber: ICountryInfo, device: Device) {
	switch (state) {
		case "online":
			const cta = '<p><i class="fas fa-phone-alt"></i> Ruf mich an</p>';
			switch (phoneNumber.country) {
				case 'at':
					return cta + austrianBox(phoneNumber, ddi);
				case "ch":
					return cta + swizzBox(phoneNumber, ddi);
				case 'de':
				default:
					return cta + germanBox(phoneNumber, ddi, device);
			}
		case "incoming":
			return '<i class="fas fa-phone-alt"></i> Werde verbunden<p class="subline">Bin bald wieder verfügbar</p>';
		case 'videochat':
			return '<i class="fas fa-video"></i> Im Live-Chat';
		case 'offline':
			return '<i class="fas fa-comments"></i> Kein Telefon';
		case "connected":
			return '<i class="fas fa-phone-alt"></i> Telefoniere<p class="subline">Bin bald wieder verfügbar</p>'
	}
}


export function formatOverlayCallBox(
	phoneNumber: ICountryInfo,
	ddi: string,
	state: HostState,
	device: Device, hostId: number,
) {
	const cta = Subline(state, hostId);

	if (state !== 'online') {
		return cta;
	}

	switch (phoneNumber.country) {
		case 'de':
			return cta + germanBox(phoneNumber, ddi, device);
		case 'at':
			return cta + austrianBox(phoneNumber, ddi);
		case "ch":
			return cta + swizzBox(phoneNumber, ddi);
	}
}
