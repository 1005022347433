/**
 * Attaches an event listener that will fire once and then autodelete
 *
 * @param el
 * @param eventname
 * @param handler
 */
export function one(el, eventname, handler) {
	if (typeof el === 'undefined') return;

	addEventListener(el, eventname, function justOnce() {
		handler();
		removeEventListener(el, eventname, justOnce);
	});
}

export function getData(el, name) {
	if (typeof el === 'undefined') return '';

	if (typeof el.dataset === 'undefined') {
		return el.getAttribute('data-' + name);
	}

	return el.dataset[name];
}

export function addEventListener(el, eventName, handler) {
	if (typeof el === 'undefined' || el === null) return;

	if (el.addEventListener) {
		el.addEventListener(eventName, handler);
	} else {
		el.attachEvent('on' + eventName, function () {
			handler.call(el);
		});
	}
}

export function removeEventListener(el, eventName, handler) {
	if (typeof el === 'undefined') return;

	if (el.removeEventListener) {
		el.removeEventListener(eventName, handler);
	} else {
		el.detachEvent('on' + eventName, handler);
	}
}

export function addClass(el, className) {
	if (typeof el === 'undefined') return;

	if (el.classList) {
		el.classList.add(className);
	} else {
		el.className += ' ' + className;
	}
}

export function removeClass(el : Element, className : string | Array<string>) {
	if (typeof el === 'undefined') return;

	if (el.classList) {
		if (typeof className === 'string') {
			el.classList.remove(className);
		} else {
			for (let name of className) {
				el.classList.remove(name);
			}
		}
	} else {
		if (typeof className === 'string') {
			el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
				' ');
		} else {
			for (let name of className) {
				el.className = el.className.replace(new RegExp('(^|\\b)' + name.split(' ').join('|') + '(\\b|$)', 'gi'),
					' ');
			}
		}
	}
}

export function show(el, style = 'block') {
	if (typeof el === 'undefined') return;

	el.style.display = style;
}

export function hide(el) {
	if (typeof el === 'undefined') return;

	el.style.display = 'none';
}

export function hideAll(elementCollection) {
	for (let n = 0; n < elementCollection.length; n++) {
		hide(elementCollection[n]);
	}
}

export function showAll(elementCollection, style = 'block') {
	for (let n = 0; n < elementCollection.length; n++) {
		show(elementCollection[n], style);
	}
}

export function remove(el) {
	if (typeof el === 'undefined') return;

	if (el !== null) {
		el.parentNode.removeChild(el);
	}
}

export function removeAll(elementCollection) {
	for (let n = elementCollection.length - 1; n >= 0; n--) {
		remove(elementCollection[n]);
	}
}

/**
 * Find the next Sibling to the element. Returns null if there is no other sibling.
 *
 * @param {Node} element
 * @return {Node | null}
 */
export function getNextSibling(element) {
	if (typeof element === 'undefined') return null;

	let e = element.nextSibling;
	while (e && 1 !== e.nodeType) {
		e = e.nextSibling;
	}
	return e;
}

export function triggerEvent(el:any, eventName:string, data:any) {
	if (typeof el === 'undefined') return;

	let event;

	// @ts-ignore
	if (window.CustomEvent) {
		// @ts-ignore
		if (typeof window.CustomEvent === 'function') {
			event = new CustomEvent(eventName, {detail: data});
		} else {
			event = document.createEvent('CustomEvent');
			event.initCustomEvent(eventName, false, false, data);
		}
	} else {
		event = document.createEvent('CustomEvent');
		event.initCustomEvent(eventName, true, true, data);
	}

	el.dispatchEvent(event);
}

/**
 * Uses an asynchronous GET for JSON data
 *
 * @param {string} url - base url: do not attach queries!
 * @param {Object} data - an key:value object which will be transformed into a query url
 * @returns {Promise} resolves with the already parsed response text if succesfull, rejects on failure with unparsed responseText
 */
export function getJson(url, data = {}) {
	return new Promise(function (resolve, reject) {
		let request = new XMLHttpRequest();
		let query = Object
			.keys(data)
			.map(function (key) {
				return key + '=' + encodeURIComponent(data[key]);
			})
			.join('&');

		request.open('GET', url + '?' + query, true);

		request.onload = function () {
			if (request.status >= 200 && request.status < 400) {
				resolve(JSON.parse(request.responseText));
			} else {
				reject(request.responseText);
			}
		};

		request.onerror = function () {
			reject(request.responseText);
		};

		request.send();
	});
}

/**
 * Waits for a few seconds before resolving the promise
 *
 * @param {int} seconds
 * @return {Promise}
 */
export function wait(seconds) {
	return new Promise(function (resolve) {
		setTimeout(resolve, seconds * 1000);
	});
}

/**
 * Turns a string into html.
 *
 * Warning: does not work for listelement, or other stuff that does not go natively into a DIV!
 *
 * @param string
 * @return {Node}
 */
export function stringToHtml(string) {
	let wrapper;

	if (string.indexOf('<li') === 0) {
		wrapper = document.createElement('ul');
	} else {
		wrapper = document.createElement('div');
	}
	wrapper.innerHTML = string;
	return wrapper.firstElementChild;
}

/**
 * Checks if an element has a single class
 *
 * Can not check if it has multiple classes!
 *
 * @param el
 * @param className
 * @return {boolean}
 */
export function hasClass(el, className) {
	if (el.classList) {
		return el.classList.contains(className);
	}

	return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
}

/**
 * Toggles as single classname on en element
 *
 * @param el
 * @param className
 */
export function toggleClass(el, className) {
	if (hasClass(el, className)) {
		removeClass(el, className);
	} else {
		addClass(el, className);
	}
}

/**
 * Cross Browser Width and Height checker
 *
 * @return {{width: number, height: number}}
 */
export function getScreenSize() {
	let w = window;
	let d = document;
	let e = d.documentElement;
	let g = d.body || d.getElementsByTagName('body')[0];
	let width = w.innerWidth || e.clientWidth || g.clientWidth;
	let height = w.innerHeight|| e.clientHeight|| g.clientHeight;

	return {width, height};
}

/**
 * Basically the jQuery finder in simple
 *
 * Returns either a single node (if we are looking for an id) or a NodeList, even if we are only searching for
 * a single thing.
 *
 * @param {String} query
 * @return {Element|NodeList|node}
 */
export function $(query) {
	if (query.match(/^#\w+$/)) {
		return document.getElementById(query.substr(1));
	}

	if (query.match(/^\.\w+$/)) {
		return document.getElementsByClassName(query.substr(1));
	}

	if (query.match(/^\w+$/)) {
		return document.getElementsByName(query);
	}

	return document.querySelectorAll(query);
}

/**
 * Throttle a function so it can be only called once in limit Microseconds
 *
 * @param {Function} func
 * @param {number} limit
 * @return {Function}
 */
export const throttle = (func, limit) => {
	let inThrottle;
	return function() {
		const args = arguments;
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => inThrottle = false, limit);
		}
	};
};

/**
 * Debounce a function, so it only is called after delay. Resets delay when called multiple times.
 *
 * @param {Function} func
 * @param {number} delay
 * @return {Function}
 */
export const debounce = (func, delay) => {
	let inDebounce;
	return function() {
		const context = this;
		const args = arguments;
		clearTimeout(inDebounce);
		inDebounce = setTimeout(() => func.apply(context, args), delay);
	};
};

/**
 * Shuffle an array using fisher/yates
 *
 * @param array
 */
export function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
}
