import {HostState} from "./Types/HostState";
import IVXCashObject from "./Interfaces/IVXCashObject";

declare var vxcash_obj:IVXCashObject;

export default function CTALine(state: HostState) {
	switch (state) {
		case "online":
			return '<i class="fas fa-phone-alt"></i> Ruf mich an';
		case "incoming":
			return '<i class="fas fa-phone-alt"></i> Werde verbunden';
		case 'videochat':
			return '<i class="fas fa-video"></i> Im Live-Chat';
		case 'offline':
			return '<i class="fas fa-comments"></i> Kein Telefon';
		case "connected":
			return '<i class="fas fa-phone-alt"></i> Telefoniere'
	}
}

export function Subline(state: HostState, hostId: number) {
	switch (state) {
		case "videochat":
			return `<small><a href="https://www.visit-x.net?s=${hostId}&w=${vxcash_obj.wmid}&ws=${vxcash_obj.campaignId}" target="_blank">Komm in meinen Chat</a></small>`;
		case "connected":
			return '<small>Stehe dir gleich wieder zur Verfügung</small>';
		default:
			return '';
	}
}
