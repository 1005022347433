import * as helper from './helper';
import * as Events from './Events';
import {ICountryMessage} from "./Interfaces/ICountryMessage";
import {Country} from "./Types/Country";
import Host from "./Host";
import Paginator from "./Paginator";
import {HostState} from "./Types/HostState";
import firstCountry from "./Functions/firstCountry";

interface Arguments {
	limit: number
	minAge: number
	maxAge: number
	attributes: string
	pagination: number
}

export default class Holder {
	private readonly _node: Element;
	private _current: Country;
	private _allHosts: Host[];
	private _filteredHosts: Host[];
	private _page: number;
	private readonly _size: number;
	private readonly _minAge: number;
	private readonly _maxAge: number;
	private readonly _attributes: string[];

	constructor(node: Element, hosts: Host[]) {
		this._node = node;
		this._current = firstCountry();
		this._allHosts = hosts;

		let args:Arguments = JSON.parse(helper.getData(node, 'arguments'));
		this._page = 0;
		this._size = args.limit;
		this._minAge = args.minAge;
		this._maxAge = args.maxAge;
		this._attributes = args.attributes.split(',')
			.map(item => item.trim())
			.filter(item => item.length !== 0);

		this.filterHosts();
		this.sortHosts();

		if (args.pagination === 1 && this._size > 0) {
			new Paginator(this._filteredHosts.length, this._size, this._node);
		}

		helper.addEventListener(window, Events.CHANGE_COUNTRY, this.handleCountryChange.bind(this));
		helper.addEventListener(window, Events.SORT_LIST, this.sortHosts.bind(this));
		helper.addEventListener(this._node, Events.PAGINATION_CHANGE_PAGE, this.updatePage.bind(this));
	}

	handleCountryChange(evt: CustomEvent) {
		let info: ICountryMessage = evt.detail;
		helper.removeClass(this._node, this._current);
		this._current = info.country;
		helper.addClass(this._node, this._current);
	}

	updatePage(evt: CustomEvent) {
		this._page = parseInt(evt.detail.page, 10);
		this.sortHosts();
	}

	filterHosts() {
		this._filteredHosts = this._allHosts.filter((host) => {
			if (this._attributes.length > 0) {
				if (!host.hasAnyAttribute(this._attributes)) {
					return false;
				}
			}

			return host.age >= this._minAge && host.age <= this._maxAge;
		});

		if (this._size > 0 && this._filteredHosts.length < this._size) {
			const knownIds = this._filteredHosts.map(host => host.id);
			const alternateHosts = this._allHosts.filter(host => {
				if (knownIds.includes(host.id)) {
					return false;
				}

				return host.age >= this._minAge && host.age <= this._maxAge;
			});

			this._filteredHosts = this._filteredHosts.concat(alternateHosts.slice(0, this._size - this._filteredHosts.length));
		}
	}

	sortHosts() {
		this._filteredHosts.sort((a, b) => {
			let onlineVariance: HostState[] = ['online', 'connected', 'incoming'];

			if (onlineVariance.indexOf(a.state) !== -1 && onlineVariance.indexOf(b.state) !== -1) {
				return 0;
			}

			if (onlineVariance.indexOf(a.state) !== -1) {
				return -1;
			}

			if (onlineVariance.indexOf(b.state) !== -1) {
				return 1
			}

			if (a.state === 'videochat' && b.state === 'videochat') {
				return 0;
			}

			if (a.state === 'videochat') {
				return -1;
			}

			if (b.state === 'videochat') {
				return 1;
			}

			return 0;
		});

		let currentHosts;

		if (this._size > 0) {
			currentHosts = this._filteredHosts.slice(this._page * this._size, (this._page + 1) * this._size);
		} else {
			currentHosts = this._filteredHosts;
		}

		while (this._node.firstChild) {
			this._node.removeChild(this._node.firstChild);
		}

		for (let host of currentHosts) {
			this._node.appendChild(host.node);
		}
	}

	update() {
		this.filterHosts();
		this.sortHosts();
	}
}
