import * as helper from './helper';
import * as Events from './Events';

export default class Paginator {
	private readonly _num: number;
	private readonly _size: number;
	private readonly _parentNode: Element;
	private _node: Element;
	private _page: number;

	constructor(count: number, size: number, parentNode: Element) {
		this._page = 0;
		this._num = count;
		this._size = size;
		this._parentNode = parentNode;

		if (count > size) {
			this._node = this.createHtml();

			this._parentNode.insertAdjacentElement('afterend', this._node);
			helper.addEventListener(this._node, 'click', this.handleClick.bind(this));
		}
	}

	private handleClick(evt) {
		evt.preventDefault();
		let element = evt.target;
		let button = element.closest('li');
		let newPage = parseInt(helper.getData(button, 'page'), 10);
		this._page = newPage;
		helper.triggerEvent(this._parentNode, Events.PAGINATION_CHANGE_PAGE, {page: newPage});

		helper.removeEventListener(this._node, 'click', this.handleClick);
		let newNode = this.createHtml();
		this._node.replaceWith(newNode);
		this._node = newNode;
		helper.addEventListener(this._node, 'click', this.handleClick.bind(this));
	}

	private createHtml() {
		let amount = Math.floor(this._num / this._size);

		let previous = `<li class="" data-page="${this._page - 1}"><a href="">&laquo;</a></li>`;
		if (this._page === 0) {
			previous = '';
		}

		let next = `<li class="" data-page="${this._page + 1}"><a href="">&raquo;</a></li>`;
		if (this._page === amount) {
			next = `<li class="active" data-page="${this._page}"><span>&raquo;</span></li>`;
		}

		let numberButtons = '';
		let start = this._page - 3;
		if (start < 0) start = 0;

		let end = this._page + 3;
		if (end > amount) end = amount;

		for (let n = start; n <= end; n++) {
			if (n === this._page) {
				numberButtons += `<li class="active" href="#" data-page="${n}"><span>${n + 1}</span></li> `
			} else {
				numberButtons += `<li class="" data-page="${n}"><a href="">${n + 1}</a></li> `
			}
		}

		return helper.stringToHtml(`
		<div class="row text-right">
			<ul class="pagination pagination-sm">
				${previous} 
				${numberButtons}
				${next}
			</ul>
		</div>
		`);
	}
}
