import SockJS from "sockjs-client";
import IBoomerOutMessage from "./Interfaces/IBoomerOutMessage";
import * as helper from './helper';
import * as Events from './Events';

export default class Communicator {
	private _connection;
	private _isConnected = false;

	constructor() {
		this.connect();
	}

	connect() {
		this._connection = new SockJS('https://ws.beianrufsex.com/boomer/vxcall');
		this._connection.onopen = this.handleNewConnection.bind(this);
		this._connection.onmessage = this.handleMessage.bind(this);
		this._connection.onclose = this.handleCloseConnection.bind(this);
		this._isConnected = true;
	}

	sendMessage(message: IBoomerOutMessage) {
		if (this._isConnected) {
			this._connection.send(JSON.stringify(message));
		}
	}

	handleNewConnection() {
		this._isConnected = true;
		this.sendMessage({type: 'requestEvents'});
	}

	handleMessage(message) {
		let jsonMessage = JSON.parse(message.data);
		if (jsonMessage.type) {
			helper.triggerEvent(window, Events.BOOMER_EVENT_STATE_CHANGE, jsonMessage);
		}
	}

	handleCloseConnection() {
		this._isConnected = false;
		window.setTimeout(() => {
			this.connect()
		}, 5000);
	}
}
