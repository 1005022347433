import IVXCashObject from "../Interfaces/IVXCashObject";
import {Country} from "../Types/Country";

declare var vxcash_obj:IVXCashObject;

export default function firstCountry() : Country {
	if (vxcash_obj.countrySelection.dePhone === '1' || vxcash_obj.countrySelection.deMobile === '1') {
		return 'de';
	}
	if (vxcash_obj.countrySelection.auPhone === '1') {
		return 'at';
	}
	if (vxcash_obj.countrySelection.chPhone === '1') {
		return 'ch';
	}

	return 'de';
}
